import React from "react"
import ProductPage from "../components/ProductPage/productPage";
import punkty from '../images/gifs/pp_punkty.gif';
import dane_o from '../images/gifs/pp_dane_o.gif'
import { injectIntl, intlShape } from "react-intl";
import { compose } from "recompose";
import withPageContext from "../pageContext";

const data = (intl) => {
  return {
    title: intl.formatMessage({ id: 'dane.title' }),
    desc: intl.formatMessage({ id: 'dane.desc' }),
    anchors: ['', intl.formatMessage({ id: 'nav.produkty.dane.potencjal.slug' }), intl.formatMessage({ id: 'nav.produkty.dane.punkty.slug' }), ''],
    sections: [
      {
        title: intl.formatMessage({ id: 'dane.potencjal.title' }),
        desc: intl.formatMessage({ id: 'dane.potencjal.desc' }),
        img: dane_o,
        link: null,
        widget: "strefy",
        widgetVersion: 0,
        imageRight: true,
        background: "01_blue.png"
      },
      {
        title: intl.formatMessage({ id: 'dane.punkty.title' }),
        desc: intl.formatMessage({ id: 'dane.punkty.desc' }),
        img: punkty,
        link: null,
        widget: "adresy",
        widgetVersion: 1,
        imageRight: false,
        background: "02_blue.png"
      }
    ],
    features: [
      {
        text: intl.formatMessage({ id: 'features.analytics' }),
        icon: "analytics"
      },
      {
        text: intl.formatMessage({ id: 'features.crm' }),
        icon: "bi_crm"
      },
      {
        text: intl.formatMessage({ id: 'features.ecommerce' }),
        icon: "ecommerce"
      }
    ]
  }
}


const DaneStatystyczne = (props) => (
  <ProductPage data={data(props.intl)} uri={props.uri} pathname={props.location.pathname} />
)

DaneStatystyczne.propTypes = {
  intl: intlShape.isRequired
};

export default compose(
  withPageContext,
  injectIntl
)(DaneStatystyczne)
